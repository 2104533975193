<template>
  <div class="main">
    <div class="box justify center mb48">
      <div class="search box center">
        <el-input v-model="searchTxt" placeholder="请输入资料名称" suffix-icon="el-icon-search"></el-input>
        <el-button @click="handleCurrentChange(1)">查询</el-button>
        <el-button @click="resetData">重置</el-button>
      </div>
      <el-button :class="!isTrue ? 'disable_icon' : ''" @click="upDoc"><span
          class="iconfont iconjiahao"></span><span>上传资料</span></el-button>
    </div>
    <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%"
      v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" prop="originalName" label="桥梁资料"></el-table-column>
      <el-table-column align="center" prop="timeO" label="上传时间"></el-table-column>
      <el-table-column align="center" width="180px" header-align="center" label-class-name="option-btn" prop="id"
        label="操作">
        <template slot-scope="scope">
          <!-- <svg :class="!isTrue?'disable_icon':''" @click="downFile(scope.row.id)" class="icon fs22 operate-txt" aria-hidden="true">
            <use xlink:href="#iconxiazai"></use>
          </svg>
          <svg :class="!isTrue?'disable_icon':''" @click="deleteFile(scope.row)" class="icon fs22 ml24 delete-txt" aria-hidden="true">
            <use xlink:href="#iconshanchu"></use>
          </svg> -->

          <div class="btn-wrapper">
            <span type="text" class="colorText mR20 pointer" size="small" @click="downFile(scope.row.id)">下载</span>
            <span type="text" class="colorText mR20 pointer" size="small" @click="check(scope.row)"
              v-if="scope.row.type !== '.docx' && scope.row.type !== '.doc'">查看</span>
            <span type="text" class="colorText pointer" size="small" @click="deleteFile(scope.row)">删除</span>
          </div>
        </template>
      </el-table-column>
      <template slot="empty">
        <span class="iconfont iconzanwushuju-"></span>
        <span>暂无数据</span>
      </template>
    </el-table>
    <common-pagination class="pageMain" :pageSize="pageSize" :total="total" :currentPage="currentPage"
      @handleCurrentChange="handleCurrentChange" />
    <el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="180px">
        <el-form-item v-if="false" label="文件标题" prop="title">
          <el-input placeholder="请输入文件标题" v-model="ruleForm.title" />
        </el-form-item>
        <el-form-item label="文件附件" prop="file_arr">
          <el-upload action=" " multiple :limit="5" :file-list="fileListAdd" :on-remove="handleAddRemove"
            :on-change="handleAddChange" :on-exceed="handleExceed" :auto-upload="false">
            <el-button v-show="!hideUploadAdd" size="small" type="primary" class="select-btn">选择</el-button>
            <span slot="tip" class="el-upload__tip">上传文件不超过50M</span>
          </el-upload>
        </el-form-item>
        <el-form-item class="btns">
          <el-button class="btn back-btn" @click="handleClose">取消</el-button>
          <el-button class="btn submit-btn" @click="submitForm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="查看文件" :visible.sync="pdfVisible" width="1200px" :before-close="handlePdfClose"
      custom-class="pdf-class">
      <div class="pdf-wrapper">
        <div class="pdf-title" v-show="!downImgUrl">
          <el-button size="small" type="primary" @click="nextPdfPage('prev')">上一页</el-button>
          {{ currentPdfPage }} / {{ pagePdfCount }}
          <el-button size="small" type="primary" @click="nextPdfPage('next')">下一页</el-button>
        </div>

        <pdf v-show="!downImgUrl" :src="pdfUrl" @num-pages="pagePdfCount = $event"
          @page-loaded="currentPdfPage = $event" :page="currentPdfPage"></pdf>
        <img v-show="downImgUrl" :src="downImgUrl" alt="" class="down-img" />
        <div class="pdf-btm">
          <span class="sureBtn back-btn pointer mR30" @click="handlePdfClose">关闭</span>
          <!-- <span class="backBtn submit-btn pointer" @click="submitForm">确定</span> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "../../components/Pagination";
import upload from "@/assets/js/upload.js";
import pdf from "vue-pdf";
export default {
  name: "documentList",
  components: {
    CommonPagination,
    pdf
  },
  data() {
    return {
      downImgUrl: "",
      numPages: undefined,
      pdfUrl: "",
      pagePdfCount: 0,
      currentPdfPage: 1,
      pdfVisible: false,
      loading: false,
      searchTxt: "",
      dialogVisible: false,
      ruleForm: { file_arr: [] },
      rules: {
        // title: [
        //   { required: true, message: '请输入文件标题', trigger: 'blur' }
        // ],
        file_arr: [{ type: "array", required: true, message: "请选择文件", trigger: "blur" }]
      },
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      interList: ["construction", "upgraded", "records", "month", "year"],
      fileListAdd: [],
      hideUploadAdd: false,
      loadingPage: null,
      isTrue: false,
      tips: "",
      projectId: ""
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      this.type = to.query.type;
      this.resetData();
      this.isTrue = sessionStorage.getItem("isTrue");
    }
  },
  mounted() {
    this.type = this.$route.query.type;
    this.isTrue = sessionStorage.getItem("isTrue");
    this.resetData();
    // this.$store.state.projectId
    this.projectId = sessionStorage.getItem("projectId");
  },
  methods: {
    nextPdfPage(flag) {
      switch (flag) {
        case "next":
          if (this.currentPdfPage >= this.pagePdfCount) {
            this.currentPdfPage = this.pagePdfCount;
            return;
          }
          this.currentPdfPage++;
          break;
        case "prev":
          if (this.currentPdfPage <= 1) {
            this.currentPdfPage = 1;
            return;
          }
          this.currentPdfPage--;
      }
    },
    handlePdfClose() {
      this.pdfVisible = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    upDoc() {
      if (!this.isTrue) return;
      this.dialogVisible = true;
      this.hideUploadAdd = false;
    },
    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 50;
      if (!isLt20M) {
        this.$message.error("文件太大了！");
        fileList.splice(-1, 1);
      } else {
        this.fileListAdd = fileList;
        this.ruleForm.file_arr = fileList;
        this.$refs.ruleForm.validate((valid) => { });
      }
      console.log(fileList);
      console.log(this.fileListAdd);
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 5) {
        this.hideUploadAdd = true;
      }
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.hideUploadAdd = false;
      this.handleAddChange(file, fileList);
    },
    // 确定新增
    submitForm() {
      this.ruleForm.file_arr = this.fileListAdd;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loadingPage = this.$loading({
            lock: true,
            text: "正在加载...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          let formData = new FormData();
          this.fileListAdd.map((item) => {
            formData.append("file", item.raw);
          });
          let url = this.interList[this.type - 1];
          this.$axios.post(`${this.baseURL}sys/sysFile/${url}/import?bizId=${this.$store.state.projectId}&originalName=${this.ruleForm.title}`, formData).then((res) => {
            if (res.data.errCode == 200) {
              this.$message({
                message: "上传成功",
                type: "success"
                // duration:0
              });
            } else {
              this.$message.error(res.data.errMsg);
            }
            this.loadingPage.close();
            this.handleClose();
          });
        }
      });
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.fileListAdd = [];
      });
      this.dialogVisible = false;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList();
    },
    getTableList() {
      this.loading = true;
      let projectId = this.$store.state.projectId;
      const params = {
        projectId: projectId,
        size: this.pageSize,
        current: this.currentPage,
        name: this.searchTxt
      };
      let url = this.interList[this.type - 1];
      this.$axios.get(`${this.baseURL}sys/sysFile/${url}/name/${this.currentPage}/${this.pageSize}/${projectId}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        if (res.errCode == 200) {
          this.tableData = data.records;
          this.total = data.total;
          this.loading = false;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    deleteFile(data) {
      if (!this.isTrue) return;
      this.$confirm(`确认删除？`, "删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      })
        .then(() => {
          this.httpDel(data.id);
        })
        .catch(function () { });
    },

    httpDel(id) {
      this.$axios.delete(`${this.baseURL}sys/sysFile/${id}`).then((res) => {
        console.log(res);
        this.$message({
          message: res.data.errMsg,
          type: "success",
          duration: 1000
        });
        if (res.data.errCode == 200) this.getTableList();
      });
    },
    resetData() {
      //重置
      this.searchTxt = "";
      this.handleCurrentChange(1);
    },
    downFile(id) {
      // 下载
      if (!this.isTrue) return;
      var a = document.createElement("a");
      a.href = `${this.downloadURL}sys/sysFile/${id}`;
      const agent = window.navigator;
      if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0] && agent.mimeTypes[0].type.match(/360/i))) {
        // a.target = '_blank'
      }
      a.click();
    },
    check(row) {
      console.log("row", row);
      // 下载
      if (this.isTrue != "true") {
        // this.$message({
        //   message: "无权限，请联系管理员!",
        //   type: "warning"
        // });
        return;
      }

      let arr = [".png", ".jpg", ".jpeg", ".svg"];
      if (arr.includes(row.type)) {
        this.pdfVisible = true;
        this.downImgUrl = `${this.downloadURL}sys/sysFile/${row.id}`;
        console.log("downImgUrl", this.downImgUrl);
        return;
      }
      // if (row.type == ".docx" || row.type == ".doc") {
      //   var a = document.createElement("a");
      //   a.href = `${this.downloadURL}sys/sysFile/${row.id}`;
      //   a.click();
      //   return;
      // }

      this.pdfVisible = true;
      //  var a = `${this.downloadURL}sys/sysFile/${id}`;
      // console.log('a',a)
      // var a = document.createElement("a");
      this.downImgUrl = "";
      this.currentPdfPage = 1;
      this.pdfUrl = pdf.createLoadingTask(`${this.downloadURL}sys/sysFile/${row.id}`);
      this.pdfUrl.promise.then((pdf) => {
        console.log("numPages", this.numPages);
        this.numPages = pdf.numPages;
      });
      console.log("pafurl", this.pdfUrl);
      // a.href = `${this.downloadURL}sys/sysFile/${id}`;
      // const agent = window.navigator;
      // // if (agent.userAgent.match(/QQ/i) || (agent.mimeTypes[0]&&agent.mimeTypes[0].type.match(/360/i))) {
      // // }
      // a.click();
      // console.log(`${this.downloadURL}sys/sysFile/${id}`, PDFObject);
      // console.log("PDFObject", PDFObject);
      // PDFObject.embed(`../../../assets/pdf/a.pdf`, "#example");
    }
  }
};
</script>

<style scoped lang="scss">
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-right: 40px;

}

.btn-wrapper span {
  flex: 1;
  text-align: center;
}


::v-deep .el-table th.el-table__cell>.option-btn.cell {
  padding-right: 50px;
  box-sizing: border-box;
}

.down-img {
  width: 100%;
}

.main ::v-deep .el-dialog__wrapper div.pdf-class {
  width: 1200px !important;
  margin-top: 0px !important;
  max-height: calc(100vh - 8px);
  overflow: auto;
  margin: auto;

  .pdf-wrapper {
    box-sizing: border-box;
    text-align: center;

    img {
      margin: auto;
    }

    .pdf-btm {
      padding-top: 20px;
      text-align: center;
    }

    .pdf-title {
      padding-bottom: 15px;
      text-align: center;
    }
  }
}

.main {
  height: 100%;

  .pl24 {
    padding-left: 24px;
  }

  .mb48 {
    margin-bottom: 48px;
  }

  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 35px;
  }

  .search {
    ::v-deep .el-input {
      width: 230px;
      height: 32px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667db;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
